import { configureStore } from "@reduxjs/toolkit";
// import thunkMiddleware from "redux-thunk";
import loginReducer from "./Slices/Login";
import registerReducer from "./Slices/Register";
import verifyReducer from "./Slices/Verification";
import updateProfileReducer from "./Slices/UpdateProfileSlice";

const store = configureStore({
  reducer: {
    login: loginReducer,
    register: registerReducer,
    verify: verifyReducer,
    updateProfile: updateProfileReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(thunkMiddleware),
  // devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
