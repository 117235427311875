import { createSlice } from "@reduxjs/toolkit";

const userData = localStorage.getItem("user");
const savedUserData = userData ? JSON.parse(userData) : [];

const initialState = {
  data: savedUserData,
  loading: false,
  error: null,
};

const updateProfileSlice = createSlice({
  name: "updateProfile",
  initialState,
  reducers: {
    profileLoading(state) {
      state.loading = true;
      state.error = null;
    },
    profileError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setProfileList(state, action) {
      localStorage.setItem("user", JSON.stringify(action.payload.data));
      state.loading = false;
      state.data = action.payload.data;
    },
  },
});

export const { profileLoading, profileError, setProfileList } =
  updateProfileSlice.actions;

export default updateProfileSlice.reducer;
