import {
  profileLoading,
  profileError,
  setProfileList,
} from "../Slices/UpdateProfileSlice";
import {
  getProfileService,
  updateProfileService,
} from "./../../Services/UpdateProfile";

export const getProfileAction = (authToken) => async (dispatch) => {
  dispatch(profileLoading());
  try {
    const response = await getProfileService(authToken);
    dispatch(setProfileList(response?.data));
    return response;
  } catch (error) {
    dispatch(profileError(error.message));
    return error.response.data.error.message;
  }
};

export const updateProfileAction =
  (formData, authToken) => async (dispatch) => {
    dispatch(profileLoading());
    try {
      const response = await updateProfileService(formData, authToken);
      dispatch(setProfileList(response?.data));
      return response;
    } catch (error) {
      dispatch(profileError(error.message));
      return error.response.data.error.message;
    }
  };
