const getTheme = (isRTL) => ({
  typography: {
    allVariants: {
      fontFamily: isRTL === "rtl" ? "LamaSans" : "Lato",
      textTransform: "none",
      " --bs-font-sans-serif":
        "system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
      "--bs-font-monospace":
        "SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace",
      "--bs-body-font-family": "Almarai,sans-serif",
    },
    h1: {
      fontWeight: 800,
    },
    h2: {
      fontWeight: 800,
    },
    h3: {
      fontWeight: 800,
    },
    h4: {
      fontWeight: 800,
    },
    h5: {
      fontWeight: 800,
    },
    h6: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    subtitle1: {
      fontWeight: 700,
    },
    subtitle2: {
      fontWeight: 400,
    },
    caption: {
      fontWeight: 400,
    },
    button: {
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      main: "#EA5069",
      background: "#EA5069",
      default: "#EA5069",
      contrastText: "#FFFFFF",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {},
      },
    },
  },
});

export default getTheme;
