import axios from "axios";

export const getProfileService = async (authToken) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/me`, {
    headers: {
      Authorization: authToken,
    },
  });
};

export const updateProfileService = async (formData, authToken) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/api/user/profile`,
    formData,
    {
      headers: {
        Authorization: authToken,
      },
    }
  );
};
