import { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PageLoader from "../Components/Loader";
import { useDispatch } from "react-redux";
import { getProfileAction } from "../Store/Actions/UpdateProfile";

const LoginForm = lazy(() => import("../Pages/Login/LoginForm"));
const ForgotPassword = lazy(() => import("../Pages/Login/ForgotPassword"));
const ResetPassword = lazy(() => import("../Pages/Login/ResetPassword"));
const RegistrationForm = lazy(() =>
  import("../Pages/Registration/RegistrationForm")
);
const Verification = lazy(() => import("../Pages/Registration/Verification"));
const Confirmation = lazy(() => import("../Pages/Registration/Confirmation"));
const Certificate = lazy(() => import("../Pages/Certificate/index"));
const UserCard = lazy(() => import("../Pages/Card/index"));
const UpdateProfile = lazy(() => import("../Pages/Profile/UpdateProfile"));
const ChangeInfo = lazy(() =>
  import("../Pages/Profile/ChangeOtherInformation")
);
const UpdatedInfoSuccess = lazy(() =>
  import("../Pages/Profile/UpdatedInfoSuccess")
);

export default function Router() {
  const location = useLocation();
  const dispatch = useDispatch();

  const authToken = localStorage.getItem("authToken");

  const scrollToTop = (url) => {
    if (!url.includes("#")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToTop(window.location.href);
  }, [location]);

  const isLoggedIn = authToken !== null;

  if (isLoggedIn) {
    const fetchData = async () => {
      await dispatch(getProfileAction(authToken));
    };

    fetchData();
  }

  return (
    <>
      <Routes>
        <Route
          path="/certificate"
          element={
            <Suspense fallback={<PageLoader />}>
              <Certificate />
            </Suspense>
          }
        />

        <Route
          path="/card"
          element={
            <Suspense fallback={<PageLoader />}>
              <UserCard />
            </Suspense>
          }
        />

        {!isLoggedIn && (
          <>
            <Route
              path="/login"
              element={
                <Suspense fallback={<PageLoader />}>
                  <LoginForm />
                </Suspense>
              }
            />

            <Route path="/" element={<Navigate to="/login" />} />

            <Route
              path="/forgot-password"
              element={
                <Suspense fallback={<PageLoader />}>
                  <ForgotPassword />
                </Suspense>
              }
            />

            <Route
              path="/reset-password"
              element={
                <Suspense fallback={<PageLoader />}>
                  <ResetPassword />
                </Suspense>
              }
            />

            <Route
              path="/register"
              element={
                <Suspense fallback={<PageLoader />}>
                  <RegistrationForm />
                </Suspense>
              }
            />

            <Route
              path="/verification"
              element={
                <Suspense fallback={<PageLoader />}>
                  <Verification />
                </Suspense>
              }
            />

            <Route
              path="/confirmation"
              element={
                <Suspense fallback={<PageLoader />}>
                  <Confirmation />
                </Suspense>
              }
            />

            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}

        {/* Other routes accessible only if logged in */}
        {isLoggedIn && (
          <>
            <Route
              path="/update-profile"
              element={
                <Suspense fallback={<PageLoader />}>
                  <UpdateProfile />
                </Suspense>
              }
            />

            <Route
              path="/change-information"
              element={
                <Suspense fallback={<PageLoader />}>
                  <ChangeInfo />
                </Suspense>
              }
            />

            <Route
              path="/update-success"
              element={
                <Suspense fallback={<PageLoader />}>
                  <UpdatedInfoSuccess />
                </Suspense>
              }
            />

            <Route path="*" element={<Navigate to="/update-profile" />} />
          </>
        )}

        <Route path="404" element={<>Not Found</>} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  );
}
